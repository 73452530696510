<template>
  <div>
    <b-container fluid>
      <b-row style="height: 75vh" align-v="center">
        <b-col cols="4"/>
        <b-col>
          <Tile title="alfaSMS">
            <b-container fluid class="my-4">
              <b-row>
                <b-col cols="1"/>
                <b-col>
                  <b-form @submit.prevent="submit">
                    <b-form-group :invalid-feedback="invalidFeedback.username">
                      <label>Benutzername</label>
                      <b-form-input :state="state.username" v-model="form.username" autofocus trim required type="text"
                                    name="username" placeholder="LDAP-Benutzername"/>
                    </b-form-group>
                    <b-form-group :invalid-feedback="invalidFeedback.password" class="mt-3">
                      <label>Passwort</label>
                      <b-form-input :state="state.password" v-model="form.password" required :type="inputType"
                                    name="password" placeholder="LDAP-Passwort"/>
                    </b-form-group>
                    <b-form-group class="mt-3">
                      <b-form-checkbox v-model="showPassword">Passwort anzeigen</b-form-checkbox>
                    </b-form-group>
                    <b-button :disabled="loading" type="submit" variant="primary">
                      <span><b-spinner v-if="loading" small class="mr-sm-2"/>Anmelden</span>
                    </b-button>
                  </b-form>
                </b-col>
                <b-col cols="1"/>
              </b-row>
            </b-container>
          </Tile>
        </b-col>
        <b-col cols="4"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tile from "@/components/utility/Tile";

export default {
  name: "Login",
  components: {
    Tile,
  },
  data() {
    return {
      showPassword: false,
      form: {
        username: '',
        password: '',
      },
      invalidFeedback: {
        username: '',
        password: '',
      },
      state: {
        username: null,
        password: null,
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions(['signIn']),
    ...mapActions(['getSites']),
    ...mapActions(['getTeams']),
    ...mapActions(['selectSite']),
    ...mapActions(['setLoadingState']),
    async submit() {
      this.loading = true
      let errors = await this.signIn(this.form)
      if(errors) {
        for(const element in this.invalidFeedback) {
          this.invalidFeedback[element] = ''
        }
        for(const element in this.state) {
          this.state[element] = null
        }
        for(const element in errors) {
          this.invalidFeedback[element] = errors[element][0]
          this.state[element] = false
        }
      }
      else {
        await this.getTeams()
        await this.getSites()
        if(this.$route.query && this.$route.query.site) {
          await this.selectSite(this.$route.query.site)
        }
        else if(!this.siteSelected) {
          await this.selectSite(this.user.site.shortname)
        }
        if(this.$route.query.continue) {
          window.location = this.$route.query.continue
        }
        else {
          await this.$router.push({name: 'Home'})
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['authenticated']),
    ...mapGetters(['user']),
    inputType() {
      return (this.showPassword) ? 'text' : 'password'
    }
  },
  async created() {
    if(this.authenticated) {
      await this.$router.push({name: 'Home'})
    }
    this.setLoadingState(false)
  }
}
</script>

<style lang="scss" scoped>
</style>
